import { render, staticRenderFns } from "./linkedin.vue?vue&type=template&id=d81fb04e&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/salabam.com/cancellara-src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d81fb04e')) {
      api.createRecord('d81fb04e', component.options)
    } else {
      api.reload('d81fb04e', component.options)
    }
    module.hot.accept("./linkedin.vue?vue&type=template&id=d81fb04e&", function () {
      api.rerender('d81fb04e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/svg/linkedin.vue"
export default component.exports